
import { defineComponent, PropType } from 'vue';

export type CellType =
'top' | 'left' | 'bottom' |
'right' | 'grid' | 'top-left' |
'top-right' | 'bottom-left' |
'bottom-right';

export default defineComponent({
  name: 'GridCellSurface',
  props: {
    cellType: {
      type: String as PropType<CellType>,
      default: () => ('grid'),
    },
    territoryColor: {
      type: String as PropType<string>,
      default: () => (''),
    },
  },
});
