
import { PlayerData } from '@/core/entities/player';
import { defineComponent, PropType, SetupContext } from 'vue';

interface Props {
  players: PlayerData[];
  currentPlayer: PlayerData;
}

export default defineComponent({
  name: 'Players',
  emits: ['pass-turn-clicked'],
  props: {
    players: {
      type: Object as PropType<PlayerData[]>,
      default: () => ([]),
    },
    currentPlayer: {
      type: Object as PropType<PlayerData>,
      default: () => ({}),
    },
  },
  setup(props: Props, { emit }: SetupContext) {
    function passTurnClicked(): void {
      emit('pass-turn-clicked');
    }
    return {
      passTurnClicked,
    };
  },
});
